// favicons
import FaviconEncinitas from '../images/encinitas.ico';
import FaviconFollyBeach from '../images/folly-beach.ico';
import FaviconLazparking from '../images/faviconlazparking.ico';
import FaviconMansfield from '../images/mansfieldct.ico';
import FaviconCityofLeavenWorth from '../images/cityofleavenworth.ico';
import FaviconKalamazoo from '../images/FaviconKalamazoo.png';
import FaviconBismarck from '../images/faviconBismarck.ico';
import FaviconParkIOP from '../images/parkiop-favicon.ico';
import FaviconParkBangor from '../images/FaviconParkBangor.ico';
import FaviconAshLand from '../images/favicon-ashland.ico';
import FaviconOceancity from '../images/faviconOceancity.ico';
import FaviconBeaufort from '../images/beaufort.ico';
import FaviconSp from '../images/favicon-sp.ico';
import FaviconHartfort from '../images/hpa-favicon.ico';
import FaviconCohasset from '../images/favicon-cohasset.ico';
import FaviconParkloyalty from '../images/favicon-parkloyalty.png';
import FaviconPeakParking from '../images/peak-favicon.ico';
import FaviconParkSepta from '../images/septa-favicon.ico';
import FaviconParkCharleston from '../images/parkcharleston-favicon.ico';
import FaviconParkKCMO from '../images/kansas-favicon.ico';
import FaviconParkEphratapa from '../images/ephratapa-favicon.ico';

// brand logo
import Encinitas from '../images/logo/encinitas.png';
import FollyBeach from '../images/logo/Folly-Beach-Logo-P.png';
import Lazparking from '../images/logo/lazparking.png';
import Mansfield from '../images/logo/mansfieldct.png';
import CityofLeavenWorth from '../images/logo/cityofleavenworth.png';
import kalamazoo from '../images/logo/kalamazoo.png';
import bismarck from '../images/logo/bismarck-logo.png';
import Parkiop from '../images/logo/parkiop-logo.png';
import ParkBangor from '../images/logo/parkBangor.png';
import AshLandLogo from '../images/logo/ashland.png';
import Oceancity from '../images/logo/oceancityLogo.png';
import BeaufortLogo from '../images/logo/beaufort-logo.png';
import SPLogo from '../images/logo/logo-sp.png';
import HartfortLogo from '../images/logo/hpa-logo.png';
import cohassetLogo from '../images/logo/cohasset.png';
import parkLoyaltyLogo from '../images/logo/Parklogo.png';
import peakParkingLogo from '../images/logo/peaklogo.png';
import parkSeptaLogo from '../images/logo/septa-logo.png';
import parkcharlestonLogo from '../images/logo/parkcharleston-logo.png';
import parkKCMOLogo from '../images/logo/kansas-logo.png';
import parkEphratapaLogo from '../images/logo/ephratapa-logo.png';

// apple favicon
import FaviconFollybeachApple from '../images/folly-beach.png';

export function assignURLs() {
  const enforcementURL = process.env.REACT_APP_ENFORCEMENT || '#';
  const processingURL = process.env.REACT_APP_PROCESSING || '#';
  const paymentURL = process.env.REACT_APP_PAYMENT || '#';

  let faviconPath = '';
  let customBranding = '';
  let customLogo = '';
  let appleIcon = '';

  const hostname = window.location.hostname;
  if (
    hostname === 'staging-admin-parkephratapa.netlify.app' ||
    hostname === 'staging-admin.parkephratapa.com' ||
    hostname === 'admin-parkephratapa.netlify.app' ||
    hostname === 'admin.parkephratapa.com'
  ) {
    faviconPath = FaviconParkEphratapa;
    customBranding = 'Park Ephratapa';
    customLogo = parkEphratapaLogo;
  } else if (
    hostname === 'staging-admin-parkkcmo.netlify.app' ||
    hostname === 'staging-admin.parkkcmo.com' ||
    hostname === 'admin-parkkcmo.netlify.app' ||
    hostname === 'admin.parkkcmo.com'
  ) {
    faviconPath = FaviconParkKCMO;
    customBranding = 'Park KCMO';
    customLogo = parkKCMOLogo;
  } else if (
    hostname === 'staging-admin-parkcharleston-sc.netlify.app' ||
    hostname === 'staging-admin.parkcharleston-sc.com' ||
    hostname === 'admin-parkcharleston-sc.netlify.app' ||
    hostname === 'admin.parkcharleston-sc.com'
  ) {
    faviconPath = FaviconParkCharleston;
    customBranding = 'Park Charleston';
    customLogo = parkcharlestonLogo;
  } else if (
    hostname === 'staging-admin-parksepta.netlify.app' ||
    hostname === 'staging-admin.parksepta.com' ||
    hostname === 'admin.parksepta.com' ||
    hostname === 'admin-parksepta.netlify.app'
  ) {
    faviconPath = FaviconParkSepta;
    customBranding = 'Park SEPTA';
    customLogo = parkSeptaLogo;
  } else if (
    hostname === 'staging-admin-parkingenforcementco.netlify.app' ||
    hostname === 'staging-admin.parkingenforcementco.com' ||
    hostname === 'admin.parkingenforcementco.com' ||
    hostname === 'admin-parkingenforcementco.netlify.app'
  ) {
    faviconPath = FaviconPeakParking;
    customBranding = 'Peak Parking';
    customLogo = peakParkingLogo;
  } else if (
    hostname === 'staging-admin-parkcohasset.netlify.app' ||
    hostname === 'staging-admin.parkcohasset.com' ||
    hostname === 'admin.parkcohasset.com' ||
    hostname === 'admin-parkcohasset.netlify.app'
  ) {
    faviconPath = FaviconCohasset;
    customBranding = 'Park Cohasset';
    customLogo = cohassetLogo;
  } else if (
    hostname === 'staging-admin-spplus.netlify.app' ||
    hostname === 'staging-admin-spplus.com' ||
    hostname === 'admin-spplus.netlify.app' ||
    hostname === 'admin-spplus.com'
  ) {
    faviconPath = FaviconSp;
    customBranding = 'SP Plus Corporation';
    customLogo = SPLogo;
  } else if (
    hostname === 'admin.parkbeaufortnc.com' ||
    hostname === 'admin.parkbeaufortnc.netlify.app' ||
    hostname === 'staging-admin-parkbeaufortnc.netlify.app' ||
    hostname === 'staging-admin.parkbeaufortnc.com'
  ) {
    faviconPath = FaviconBeaufort;
    customBranding = 'Beaufort';
    customLogo = BeaufortLogo;
  } else if (
    hostname === 'admin-aceparking.netlify.app' ||
    hostname === 'admin-aceparking.com' ||
    hostname === 'staging-admin-aceparking.netlify.app' ||
    hostname === 'staging-admin-aceparking.com'
  ) {
    faviconPath = FaviconEncinitas;
    customBranding = 'Ace Parking';
    customLogo = Encinitas;
  } else if (
    hostname === 'admin-follybeach.parkloyalty.com' ||
    hostname === 'admin-follybeach.parkloyalty.netlify.app' ||
    hostname === 'staging-admin-follybeach-parkloyalty.netlify.app' ||
    hostname === 'staging-admin-follybeach.parkloyalty.com'
  ) {
    faviconPath = FaviconFollyBeach;
    customBranding = 'City of Follybeach';
    customLogo = FollyBeach;
    appleIcon = FaviconFollybeachApple;
  } else if (
    hostname === 'admin.parkcountryclubplaza.com' ||
    hostname === 'admin.parkcountryclubplaza.netlify.app' ||
    hostname === 'staging-admin-parkcountryclubplaza.netlify.app' ||
    hostname === 'staging-admin.parkcountryclubplaza.com'
  ) {
    faviconPath = FaviconLazparking;
    customBranding = 'Laz Parking';
    customLogo = Lazparking;
  } else if (
    hostname === 'admin.parkmansfield.com' ||
    hostname === 'admin.parkmansfield.netlify.app' ||
    hostname === 'staging-admin-parkmansfield.netlify.app' ||
    hostname === 'staging-admin.parkmansfield.com'
  ) {
    faviconPath = FaviconMansfield;
    customBranding = 'Mansfield';
    customLogo = Mansfield;
  } else if (
    hostname === 'admin-leavenworth.netlify.app' ||
    hostname === 'admin-leavenworth.com' ||
    hostname === 'staging-admin-leavenworth.netlify.app' ||
    hostname === 'staging-admin-leavenworth.com'
  ) {
    faviconPath = FaviconCityofLeavenWorth;
    customBranding = 'City of Leavenworth';
    customLogo = CityofLeavenWorth;
  } else if (
    hostname === 'admin.parkkalamazoo.com' ||
    hostname === 'admin.parkkalamazoo.netlify.app' ||
    hostname === 'staging-admin-parkkalamazoo.netlify.app' ||
    hostname === 'staging-admin.parkkalamazoo.com'
  ) {
    faviconPath = FaviconKalamazoo;
    customBranding = 'Kalamazoo';
    customLogo = kalamazoo;
  } else if (
    hostname === 'admin.parkbismarcknd.com' ||
    hostname === 'admin.parkbismarcknd.netlify.app' ||
    hostname === 'staging-admin-parkbismarcknd.netlify.app' ||
    hostname === 'staging-admin.parkbismarcknd.com'
  ) {
    faviconPath = FaviconBismarck;
    customBranding = 'Bismarck';
    customLogo = bismarck;
  } else if (
    hostname === 'admin.parkiop.com' ||
    hostname === 'admin.parkiop.netlify.app' ||
    hostname === 'staging-admin-parkiop.netlify.app' ||
    hostname === 'staging-admin.parkiop.com'
  ) {
    faviconPath = FaviconParkIOP;
    customBranding = 'Park IOP';
    customLogo = Parkiop;
  } else if (
    hostname === 'admin.parkbangor.org' ||
    hostname === 'admin.parkbangor.netlify.app' ||
    hostname === 'staging-admin-parkbangor.netlify.app' ||
    hostname === 'staging-admin.parkbangor.org'
  ) {
    faviconPath = FaviconParkBangor;
    customBranding = 'Park Bangor';
    customLogo = ParkBangor;
  } else if (
    hostname === 'admin.parkashlandoregon.com' ||
    hostname === 'admin.parkashlandoregon.netlify.app' ||
    hostname === 'staging-admin-parkashlandoregon.netlify.app' ||
    hostname === 'staging-admin.parkashlandoregon.com'
  ) {
    faviconPath = FaviconAshLand;
    customBranding = 'AshLand';
    customLogo = AshLandLogo;
  } else if (
    hostname === 'admin.parkriveroakssc.com' ||
    hostname === 'admin.parkriveroakssc.netlify.app' ||
    hostname === 'staging-admin-parkriveroakssc.netlify.app' ||
    hostname === 'staging-admin.parkriveroakssc.com'
  ) {
    faviconPath = FaviconLazparking;
    customBranding = 'Park River Oaks';
    customLogo = Lazparking;
  } else if (
    hostname === 'admin-hpa2pay.netlify.app' ||
    hostname === 'admin.hpa2pay.com' ||
    hostname === 'staging-admin-hpa2pay.netlify.app' ||
    hostname === 'staging-admin.hpa2pay.com'
  ) {
    faviconPath = FaviconHartfort;
    customBranding = 'Hartford Parking Authority';
    customLogo = HartfortLogo;
  }else if(
    hostname === 'admin.parkocmd.com' ||
    hostname === 'admin.parkocmd.netlify.app' ||
     hostname === 'staging-admin.parkocmd.com' ||
     hostname === 'staging-admin-parkocmd.netlify.app'
  ){
    faviconPath = FaviconOceancity;
    customBranding = 'OCMD';
    customLogo = Oceancity;
  }
  else {
    faviconPath = FaviconParkloyalty;
    customBranding = 'Park Loyalty';
    customLogo = parkLoyaltyLogo;
  }

  return {
    paymentURL,
    processingURL,
    enforcementURL,
    faviconPath,
    customBranding,
    customLogo,
    appleIcon,
  };
}
